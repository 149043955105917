import * as React from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../App/Store';
import { useTranslation } from 'react-i18next';

import {
    Container, 
    Stack,
    Typography,
} from '@mui/material';

import StudentListItem from './StudentListItem';
import ExperimentHeader from './ExperimentHeader';
import EndExperimentButton from './EndExperimentButton';

import BackButton from '../../Components/Shared/BackButton';
import QuerryErrorSnackbarAlert from '../../Components/Shared/QuerryErrorAlert';
import LoaderCircularProgress from '../../Components/Shared/LoaderCircularProgress';

import { Experiments } from '../../../App/API/Experiments';
import { connectSocket } from '../../../App/SocketSystem/SocketSystem';
import Logger from '../../../App/Framework/Utils/Logger';

export default function TeacherExperimentationView()
{
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { experimentationId: experimentationIdParam = '' } = useParams();

    const experimentId = parseInt(experimentationIdParam);
    
    const token = useSelector((state: RootState) => state.User.token);
    const userType = useSelector((state: RootState) => state.User.userType);

    const { data: experimentProgressesData, isLoading: isLoadingProgress, error: expProgressError, refetch: refetchExpProgress } 
        = Experiments.useTeacherGetExperimentProgressesQuery({
            token,
            experimentId: experimentId || -1
        });

    const { data: experiment, isLoading: isLoadingExp, error: expError } = Experiments.useTeacherGetExperimentQuery({
        token,
        experimentId: experimentId || -1
    });

    const [ teacherEndExperiment, { error: endExpError } ] 
        = Experiments.useTeacherEndExperimentMutation();

    React.useEffect(() => 
    {
        const socket = connectSocket('experiment-view', token);

        socket.on('refresh', (experimentIdToRefresh) => 
        {
            if(experimentIdToRefresh === experimentId)
                refetchExpProgress();
        });
        
        return () => 
        {
            socket.disconnect();
        }
    }, [ token, experimentId, refetchExpProgress ]);

    if(userType !== 'teacher')
    {
        Logger.error('This component is not available for other user than teachers !');
        return <></>;
    }

    if(experimentationIdParam === '')
    {
        navigate('/experimentations/my-experimentations');
        return <></>;
    }

    const studentsList : JSX.Element[] = [];

    if(experimentProgressesData)
    {
        for(const progress of experimentProgressesData.progresses)
        {
            studentsList.push((
                <StudentListItem
                    key = { progress.Student.id }
                    progress = { progress }
                    experimentId = { experimentId }
                    steps = { experimentProgressesData.steps }
                />
            ));
        }
    }

    async function EndExperiment()
    {
        try {
            await teacherEndExperiment({
                token,
                experimentId: experimentId || -1
            }).unwrap();

            navigate('/experimentations/my-experimentations');
        }
        catch(err)
        {
            Logger.error('While endExperiment', err);
        }
    }

    return (
        <>
            <QuerryErrorSnackbarAlert error = { expError } />
            <QuerryErrorSnackbarAlert error = { expProgressError } />
            <QuerryErrorSnackbarAlert error = { endExpError } />
            
            <Container maxWidth='lg'>
                <BackButton />
            </Container>
            <Container maxWidth='xl'>
            <LoaderCircularProgress display = { isLoadingExp } />
            {
                experiment && 
                <ExperimentHeader 
                    scenario = { experimentProgressesData?.scenario }
                    experiment = { experiment }
                />
            }
            </Container>
            <Container>
                <LoaderCircularProgress display = { isLoadingProgress } />
                {
                    experimentProgressesData &&
                    <>
                        <Stack
                            direction='row-reverse'
                            marginY= '1rem'
                        >
                            {
                                experiment?.isOngoing &&
                                <EndExperimentButton
                                    onEndExperiment = { EndExperiment }
                                />
                            }
                        </Stack>
                        <Stack
                            direction='column'
                        >
                            { studentsList }
                            { 
                                studentsList.length < 1 &&
                                <Typography variant='body1' color='text.secondary'>
                                    { t('experimentation_view.list.no_progress') }
                                </Typography>
                            }
                        </Stack>
                    </>
                }
                
            </Container>
        </>
    );
}