import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
    Card,
    CardActionArea,
    Grid, 
    IconButton, 
    Stack, 
    Tooltip, 
    Typography,
} from '@mui/material';

import {
    Info as InfoIcon,
} from '@mui/icons-material';

import { ScenarioStep } from '../../../App/Store/SimpleCreateExperiment/Types';
import LinearProgressWithLabel from '../../Components/Shared/LinearProgressWithLabel';

import { DownloadStatsButtonIcon } from './DownloadStats';
import { TeacherStudentExperimentProgress } from '../../../App/API/Experiments';

type StudentItemProps = 
{
    progress: TeacherStudentExperimentProgress,
    steps: ScenarioStep[],
    experimentId: number
}

export default function StudentListItem({ progress, steps, experimentId } : StudentItemProps)
{
    const { t } = useTranslation();
    const navigate = useNavigate();

    function OnClickProgressDetail()
    {
        navigate(`/experimentations/student-details/${experimentId}_${progress.StudentId}`);
    }

    return (
        <Card
            sx = {{
                marginY: '0.2rem'
            }}
        >
            <CardActionArea
                sx = {{
                    padding: '0.5rem'
                }}
                onClick={ OnClickProgressDetail }
            >
                <Grid
                    container
                    direction = 'row'
                    alignItems='center'
                    
                    spacing={ 1 }
                >
                    <Grid
                        item
                        xs = { 2 }
                    >
                        <Typography variant='body2'>{ progress.Student.firstName } { progress.Student.lastName }</Typography>
                    </Grid>
                    <Grid
                        item
                        xs = { 8 }

                        container
                        direction = 'row'
                        spacing = { 1 }
                    >
                        <Grid item>
                            <Typography variant='body2'> { progress.currentStep }/{ steps.length }</Typography>
                        </Grid>

                        <Grid
                            item
                            xs = { 10 }
                        >
                            <LinearProgressWithLabel
                                value = { (progress.currentStep / steps.length) * 100 }
                                color = { progress.completed ? 'success' : 'info' }
                                percentageTextColor = "text.primary"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs = { 2 }
                    >
                        <Stack
                            direction='row-reverse'
                            alignItems='end'
                        >
                            {
                                progress.completed && 
                                <DownloadStatsButtonIcon 
                                    student = { progress.Student }
                                    experimentId = { progress.ExperimentId }
                                    
                                    onMouseDown={ event => event.stopPropagation() }
                                    onTouchStart={ event => event.stopPropagation() }
                                    onClick = { 
                                        event => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                        }
                                    }                               
                                />
                            }
                            <Tooltip
                                title = { t('experimentation_view.nav_to_student_details') }
                                placement = 'top'
                            >
                                <IconButton>
                                    <InfoIcon fontSize='medium'/>
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    );
}