import { API, ApiTags, DefaultPostRequest } from "..";

import { Experimentation } from "../../Store/Experiment/Types";
import { Scenario, ScenarioStep } from "../../Store/SimpleCreateExperiment/Types";
import { Student } from "../../Store/CreateExpriment/Types";

import { TeacherStudentExperimentProgress, StudentExperimentProgress, StudentExperimentatInfo, StepErrorInfo } from './Types';
export * from './Types';

export namespace Experiments
{
    export type NewExperimentForClassRequest = DefaultPostRequest &
    {
        classId: number,
        scenarioId: number,
        skipPreparationSteps: boolean,
    };

    export type NewExperimentForClassResponse = 
    {
        experimentId: number,
    };

    export type ExprimentationsRequest = DefaultPostRequest & {};
    export type ExprimentationsResponse = Experimentation[];

    export type ExprimentationRequest = DefaultPostRequest & 
    {
        experimentId: number
    };

    export type ExpriementationResponse = Experimentation;
    export type ExpriementationInfoResponse = StudentExperimentatInfo;

    export type EndExprimentationsRequest = DefaultPostRequest & 
    {
        experimentId: number
    };

    // TODO : Should be removed when back will send data in a correct format
    function ExperimentsResponseParser(responseData : any[]) : ExprimentationsResponse
    {
        return responseData.map(ExperimentResponseParser);
    }

    // TODO : Should be removed when back will send data in a correct format
    function ExperimentResponseParser({ 
            id, 
            isOngoing, 
            skipPreparationSteps, 
            SchoolClassId, 
            ScenarioId, 
            TeacherId, 
            SchoolClass, 
            Teacher, 
            startDate, 
            endDate 
        } : any) : ExpriementationResponse
    {
        return {
            id, isOngoing, skipPreparationSteps, ScenarioId, 
            TeacherId,
            TeacherFirstName: Teacher?.firstName,
            TeacherLastName: Teacher?.lastName,
            SchoolClassId,
            schoolClassName : SchoolClass.name,
            startDate : startDate,
            endDate : endDate,
        };
    }

    export type ExperimentProgressRequest = DefaultPostRequest & 
    {
        experimentId: number
    }

    export type ExperimentProgressesRequest = DefaultPostRequest & 
    {
        experimentId: number
    }

    export type TeacherStudentsExperimentProgressesResponse =
    {
        progresses: TeacherStudentExperimentProgress[]
        scenario: Scenario,
        steps: ScenarioStep[],
    };

    export type StudentOneExperimentProgressResponse = {
        scenario: Scenario,
        steps: ScenarioStep[],
        progress: StudentExperimentProgress,
    };

    export type StudentExperimentProgressesResquest = DefaultPostRequest;
    export type StudentExperimentProgressesResponse = StudentExperimentProgress[];

    export const api = API.injectEndpoints(
    {
        endpoints: build => (
        {
            // Teacher Create / Get / End experiments
            newExperiementForClass: build.mutation<NewExperimentForClassResponse, NewExperimentForClassRequest>(
            {
                query: ({token, classId, scenarioId, skipPreparationSteps}) => 
                ({
                    url: `experiments/new-experiment-for-class`,
                    method: 'POST',
                    formData : true,
                    body: {
                        token,
                        class_id: classId,
                        scenario_id: scenarioId,
                        skip_preparation_steps: skipPreparationSteps,
                    },
                    responseHandler: 'text'
                }),
                transformResponse: (responseData : string) => 
                ({
                    experimentId: Number(responseData)
                }),
                invalidatesTags: [ ApiTags.Experiments ]
            }),

            teacherEndExperiment : build.mutation<void, EndExprimentationsRequest>(
            {
                query: (reqData) => 
                ({
                    url: `experiments/teacher/end-experiment`,
                    method: 'POST',
                    formData : true,
                    body: reqData,
                    responseHandler: 'text'
                }),
                invalidatesTags: [ ApiTags.Experiments ]
            }),

            teacherActiveExpriementations: build.query<ExprimentationsResponse, ExprimentationsRequest>(
            {
                query: (reqData) => 
                ({
                    url: `experiments/teacher/current-experiments`,
                    method: 'POST',
                    formData : true,
                    body: reqData
                }),
                transformResponse: ExperimentsResponseParser,
                providesTags: [ ApiTags.Experiments ],
            }),

            teacherOldExpriementations: build.query<ExprimentationsResponse, ExprimentationsRequest>(
            {
                query: (reqData) => 
                ({
                    url: `experiments/teacher/old-experiments`,
                    method: 'POST',
                    formData : true,
                    body: reqData
                }),
                transformResponse: ExperimentsResponseParser,
                providesTags: [ ApiTags.Experiments ],
            }),

            teacherGetExperiment: build.query<ExpriementationResponse, ExprimentationRequest>(
            {
                query: (reqData) => 
                ({
                    url: `experiments/teacher/experiment`,
                    method: 'POST',
                    formData : true,
                    body: reqData
                }),
                transformResponse: ExperimentResponseParser,
                providesTags: [ ApiTags.Experiments ],
            }),


            // Student Get experiments

            studentActiveExpriementations: build.query<ExprimentationsResponse, ExprimentationsRequest>(
            {
                query: (reqData) => 
                ({
                    url: `experiments/student/current-experiments`,
                    method: 'POST',
                    formData : true,
                    body: reqData
                }),
                transformResponse: ExperimentsResponseParser,
                providesTags: [ ApiTags.Experiments ],
            }),

            studentOldExpriementations: build.query<ExprimentationsResponse, ExprimentationsRequest>(
            {
                query: (reqData) => 
                ({
                    url: `experiments/student/old-experiments`,
                    method: 'POST',
                    formData : true,
                    body: reqData
                }),
                transformResponse: ExperimentsResponseParser,
                providesTags: [ ApiTags.Experiments ],
            }),

            studentGetExperimentInfos: build.query<ExpriementationInfoResponse, ExprimentationRequest>(
            {
                query: (reqData) => 
                ({
                    url: `student/get-experiment-infos`,
                    method: 'POST',
                    formData : true,
                    body: reqData
                }),
            }),

            studentGetExperiment: build.query<ExpriementationResponse, ExprimentationRequest>(
            {
                query: (reqData) => 
                ({
                    url: `experiments/student/experiment`,
                    method: 'POST',
                    formData : true,
                    body: reqData
                }),
                transformResponse: ExperimentResponseParser,
                providesTags: [ ApiTags.Experiments ],
            }),

            // Teacher Get students experiments details

            teacherGetExperimentProgresses: build.query<TeacherStudentsExperimentProgressesResponse, ExperimentProgressesRequest>(
            {
                query: (reqData) => 
                ({
                    url: `experiments/teacher/get-experiment-students-progress`,
                    method: 'POST',
                    formData : true,
                    body: reqData
                }),
                transformResponse: ({ progresses, scenario } : any) => 
                ({
                    progresses,
                    scenario,
                    steps: scenario.ScenarioSteps,
                }),
                providesTags: [ ApiTags.Experiments ],
            }),

            // Student Get experiments details

            studentGetExperimentProgress: build.query<StudentOneExperimentProgressResponse, ExperimentProgressRequest>(
            {
                query: (reqData) => 
                ({
                    url: `experiments/student/get-student-progress`,
                    method: 'POST',
                    formData : true,
                    body: reqData
                }),
                transformResponse: ({ progress, scenario } : any) => 
                ({
                    progress,
                    scenario,
                    steps: scenario.ScenarioSteps
                }),
                providesTags: [ ApiTags.Experiments ],
            }),

            studentGetExperimentProgresses: build.query<StudentExperimentProgressesResponse, StudentExperimentProgressesResquest>(
            {
                query: (reqData) => 
                ({
                    url: `experiments/student/get-student-progresses`,
                    method: 'POST',
                    formData : true,
                    body: reqData
                }),
                providesTags: [ ApiTags.Experiments ],
            }),
        }),
    });

    export const {
        useNewExperiementForClassMutation,
        useTeacherEndExperimentMutation,
        useTeacherActiveExpriementationsQuery,
        useTeacherOldExpriementationsQuery,
        useTeacherGetExperimentQuery,
        useStudentActiveExpriementationsQuery,
        useStudentOldExpriementationsQuery,
        useStudentGetExperimentQuery,
        useTeacherGetExperimentProgressesQuery,
        useStudentGetExperimentProgressQuery,
        useStudentGetExperimentProgressesQuery,
    } = api;
}