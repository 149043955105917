import { UnityConfig } from "react-unity-webgl";

const DevUnityContextPaths : UnityConfig = 
{
    loaderUrl: "/NeurolaboDev/Neurolabo.loader.js",
    dataUrl: "/NeurolaboDev/Neurolabo.data",
    frameworkUrl: "/NeurolaboDev/Neurolabo.framework.js",
    codeUrl: "/NeurolaboDev/Neurolabo.wasm",
    streamingAssetsUrl: "/StreamingAssets",
    companyName: "Practeex",
    productName: "Neurolabo",
};

const ProdUnityContextPaths : UnityConfig = 
{
    loaderUrl: "/Neurolabo/Neurolabo.loader.js",
    dataUrl: "/Neurolabo/Neurolabo.data.gz",
    frameworkUrl: "/Neurolabo/Neurolabo.framework.js.gz",
    codeUrl: "/Neurolabo/Neurolabo.wasm.gz",
    streamingAssetsUrl: "/StreamingAssets",
    companyName: "Practeex",
    productName: "Neurolabo",
};

function GetUnityContextPaths() : UnityConfig
{
    if(process.env.REACT_APP_ENV === 'DEV')
        return DevUnityContextPaths;
                
    if(process.env.REACT_APP_ENV === 'PROD')
        return ProdUnityContextPaths;

    throw new Error('Invalid REACT_APP_ENV in .env file');
}

export const UnityContextPaths = GetUnityContextPaths();

export const CheckAppTokenUrl = `${process.env.REACT_APP_URL_API}users/check-app-token`;

export const ProgressUpdateUrl = `${process.env.REACT_APP_URL_API}steps/update-experiment-progress`;
export const SendStepErrorUrl = `${process.env.REACT_APP_URL_API}steps/send-step-error`;

export const SendResulDatatUrl = `${process.env.REACT_APP_URL_API}stats/send-experiment-result`;
export const SendResultDataNoProgressUrl = `${process.env.REACT_APP_URL_API}stats/export-stats-no-progress`;