import * as React from 'react';

import { useTranslation } from 'react-i18next';

import {
    Card,
    Paper,
    Typography,
    CircularProgress,
    lighten,
    useMediaQuery,
    useTheme,
    CardContent
} from '@mui/material';

import { 
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineDot,
    TimelineConnector,
    TimelineContent,
    TimelineOppositeContent
} from '@mui/lab';

import { 
    Check as CheckIcon
} from '@mui/icons-material';

import { StudentExperimentProgress, TeacherStudentExperimentProgress } from '../../../../App/API/Experiments';
import { Scenario, ScenarioStep } from '../../../../App/Store/SimpleCreateExperiment/Types';
import { StepErrorInfo } from '../../../../App/API/Experiments/Types';

type ExperimentStudentDetailsTimeLineProps = 
{
    experimentProgress: {
        scenario: Scenario;
        steps: ScenarioStep[];
        progress: StudentExperimentProgress | TeacherStudentExperimentProgress;
    }
}

export default function ExperimentStudentDetailsTimeLine({ experimentProgress } : ExperimentStudentDetailsTimeLineProps)
{
    const { t } = useTranslation();

    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.up('md'));

    if(experimentProgress.steps?.length < 1
        || !experimentProgress.progress
    )
    {
        return (
            <Typography variant='body1' color='text.secondary' mt = '1.5rem'>
                { t('experimentation_view.student_details.no_progress') }
            </Typography>
        );
    }
    
    const experimentProgressElements : JSX.Element[] = [];

    for(let i = 0; i < experimentProgress.steps?.length; ++i)
    {
        const step = experimentProgress.steps[i];

        const errors = GetParsedErrorsForStep(step, experimentProgress.progress, t);

        const isCurrentStep = i === experimentProgress.progress?.currentStep;

        const content = (i < experimentProgress.progress?.currentStep)
            ? <FinishedStepTimeLineElement index = { i } step = { step } isCurrentStep = { isCurrentStep } errors={errors} t={t}/>
            : <UnfinishedStepTimeLineElement index = { i } step = { step } isCurrentStep = { isCurrentStep } errors={errors} t={t}/>;

        const stepElement = (
            <TimelineItem
                key = { step.name + i }
            >
                { !isLarge && <TimelineOppositeContent display='none' /> }
                { content }
            </TimelineItem>
        );

        experimentProgressElements.push(stepElement);
    }

    return (
        <Timeline position= { isLarge ? 'alternate-reverse' : 'right' }>
            { experimentProgressElements }
        </Timeline>
    );
}

/** 
 * The tow following components can be factorized in a single one
 * 
 */

type StepTimeLineElementProps =
{
    index: number,
    step: ScenarioStep,
    isCurrentStep?: boolean,
    errors: ParsedStepError[],
    t : any
}

type ParsedStepError =
{
    title : string,
    content : string,
    time : Date
}

function FinishedStepTimeLineElement({ index, step, errors, t } : StepTimeLineElementProps) : JSX.Element
{
    return (
        <>
            <TimelineSeparator>
                <TimelineConnector sx={{ bgcolor: 'secondary.main' }}/>
                <TimelineDot color='success'>
                    <CheckIcon fontSize='small' htmlColor='white'/>
                </TimelineDot>
                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Card
                    square
                >
                    <Paper
                        square
                        sx = {{
                            bgcolor: theme => lighten(theme.palette.background.paper, 0.1),
                            paddingX: '0.6rem',
                            paddingY: '0.2rem',
                        }}
                    >
                        <Typography variant="h6" component="span">
                            { `${t('experimentation_view.student_details.step_number')}${ index + 1 }` } - { t(`scenario_steps.${step.name}`) }
                        </Typography>
                    </Paper>
                    {errors.length > 0 && <CardContent><Typography variant='h6'>{ t('step_errors') }</Typography></CardContent>}
                    {
                        errors.map((error, index) => (
                                <CardContent key={index}>
                                    <Typography key={index + '.title'}>{ "○ " + error.title }</Typography>
                                    <Typography key={index + '.content'} variant='subtitle2' style={{whiteSpace: 'pre-wrap'}}><div dangerouslySetInnerHTML={{__html: error.content}}></div></Typography>
                                </CardContent>
                                ))
                    }
                        
                </Card>
            </TimelineContent>
        </>
    );
}

function UnfinishedStepTimeLineElement({ index, step, isCurrentStep, errors, t } : StepTimeLineElementProps) : JSX.Element
{
    return (
        <>
            <TimelineSeparator>
                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                <TimelineDot color = 'primary'>
                { 
                    isCurrentStep &&
                    <CircularProgress size={ 30 } sx = {{ color: 'white' }}/>
                }
                </TimelineDot>
                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Card
                    square
                >
                    <Paper
                        square
                        sx = {{
                            bgcolor: theme => lighten(theme.palette.background.paper, 0.1),
                            paddingX: '0.6rem',
                            paddingY: '0.2rem',
                        }}
                    >
                        <Typography variant="h6" component="span">
                            { `Etape N°${ index + 1 }` } - { t(`scenario_steps.${step.name}`) }
                        </Typography>
                    </Paper>
                    { 
                        errors.length > 0 && <Typography>{ t('step_errors') }</Typography> &&
                        errors.map((error, index) => (
                            <CardContent key={index}>
                                <Typography key={index + '.title'}>{ "○ " + error.title }</Typography>
                                <Typography key={index + '.content'} variant='subtitle2' style={{whiteSpace: 'pre-wrap'}}><div dangerouslySetInnerHTML={{__html: error.content}}></div></Typography>
                            </CardContent>
                            ))
                    }
                </Card>
            </TimelineContent>
        </>
    );
}

function GetParsedErrorsForStep(step: ScenarioStep, progress: StudentExperimentProgress | TeacherStudentExperimentProgress, t: any) : ParsedStepError[]
{
    const errors : StepErrorInfo[] = [];
    for(const error of progress.StepErrors)
    {
        if(error.ScenarioStepId === step.id)
        {
            errors.push(error);
        }
    }

    const sorted_errors : StepErrorInfo[] = errors.sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime());

    const parsed_errors : ParsedStepError[] = sorted_errors.map(error => GetOneParsedError(error, t));

    return parsed_errors;
}

function GetOneParsedError(error: StepErrorInfo, t: any) : ParsedStepError {
    var parsed_error : ParsedStepError = {} as ParsedStepError;
    var titleKey = 'step_error_types.' + error.type;
    parsed_error.title = t(titleKey);
    parsed_error.time = error.time;
    parsed_error.content = '';

    switch(error.type)
    {
        // AJOUTER LES TYPES D'ERREURS ICI, AVEC UN PARSING DU CONTENT DIFFERENT EN FONCTION DU TYPE
        case "equipment_selection_error":

            for(const zone of JSON.parse(error.content)) {
                if(parsed_error.content != '')
                {
                    parsed_error.content += ', ';
                }
                parsed_error.content += t('step_error_types.equipment_selection.' + zone);
            }
            break;
        case "mouse_groups_selection_error":
            parsed_error.content = t("step_error_types.mouse_groups_selection." + error.content);
            break;
        case "wrong_syringe_error":
            parsed_error.content = t("step_error_types.wrong_syringe." + error.content);
            break;
        case "wrong_grab_way_error":
            parsed_error.content = t("step_error_types.wrong_grab_way." + error.content);
            break;
        case "wrong_site_injection_error":
            parsed_error.content = t("step_error_types.wrong_site_injection." + error.content);
            break;
        case "stereotaxy.wrong_or_missing_products_and_tools_error":
            let stereotaxyWrongAndMissingProductsAndToolsError = JSON.parse(error.content);
            let missingProductsAndToolsItems = stereotaxyWrongAndMissingProductsAndToolsError.MissingItems;
            let wrongProductsAndToolsItems = stereotaxyWrongAndMissingProductsAndToolsError.WrongItems;

            if(missingProductsAndToolsItems.length > 0) {
                parsed_error.content += "<b>" + t("step_error_types.stereotaxy.missing_products_and_tools") + "</b> : ";
                for(const item of missingProductsAndToolsItems) {
                    parsed_error.content += t("step_error_types.stereotaxy.products_and_tools." + item) + ", ";
                }
                parsed_error.content = parsed_error.content.slice(0, -2);
            }

            if(wrongProductsAndToolsItems.length > 0) {
                parsed_error.content += "\n<b>" + t("step_error_types.stereotaxy.wrong_products_and_tools") + "</b> : ";
                for(const item of wrongProductsAndToolsItems) {
                    parsed_error.content += t("step_error_types.stereotaxy.products_and_tools." + item) + ", ";
                }
                parsed_error.content = parsed_error.content.slice(0, -2);
            }

            break;
        case "stereotaxy.tools_cleaning_not_valid_error":
            let notCleanedItems = JSON.parse(error.content);
            if(notCleanedItems.length > 0){
                parsed_error.content += "<b>" + t("step_error_types.stereotaxy.not_cleaned_items") + "</b> : ";
                for(const item of notCleanedItems) {
                    parsed_error.content += t("step_error_types.stereotaxy.products_and_tools." + item) + ", ";
                }
            }
            break;
            case "stereotaxy.wrong_or_missing_anesthetics_error":
                let stereotaxyWrongAndMissingAnestheticsError = JSON.parse(error.content);
                let missingAnestheticsItems = stereotaxyWrongAndMissingAnestheticsError.MissingItems;
                let wrongAnestheticsItems = stereotaxyWrongAndMissingAnestheticsError.WrongItems;
    
                if(missingAnestheticsItems.length > 0) {
                    parsed_error.content += "<b>" + t("step_error_types.stereotaxy.missing_anesthetics") + "</b> : ";
                    for(const item of missingAnestheticsItems) {
                        parsed_error.content += t("step_error_types.stereotaxy.anesthetics." + item) + ", ";
                    }
                    parsed_error.content = parsed_error.content.slice(0, -2);
                }
    
                if(wrongAnestheticsItems.length > 0) {
                    parsed_error.content += "\n <b>" + t("step_error_types.stereotaxy.wrong_anesthetics") + "</b> : ";
                    for(const item of wrongAnestheticsItems) {
                        parsed_error.content += t("step_error_types.stereotaxy.anesthetics." + item) + ", ";
                    }
                    parsed_error.content = parsed_error.content.slice(0, -2);
                }
                break;
            case "stereotaxy.begin_surgery.missing_steps_error":
                let beginSurgeryMissingSteps = JSON.parse(error.content);
                if(beginSurgeryMissingSteps.length > 0){
                    for(const step of beginSurgeryMissingSteps) {
                        parsed_error.content += t("step_error_types.stereotaxy.begin_surgery.missing_steps." + step) + ", ";
                    }
                    parsed_error.content = parsed_error.content.slice(0, -2);
                }
                break;
            case "stereotaxy.piercing_error":
                let piercingErrors = JSON.parse(error.content);
                if(piercingErrors.length > 0){
                    for(const error of piercingErrors) {
                        parsed_error.content += t("step_error_types.stereotaxy.piercing_errors." + error) + "\n";
                    }
                    //parsed_error.content = parsed_error.content.slice(0, -1);
                }
                break;
        default:
            if(parsed_error.content != null && parsed_error.content != '') {
                parsed_error.content = t('step_error_types.' + error.type + "." + error.content);
            }
            break;
    }

    return parsed_error;
}